import "./ContactMe.css";
import { useRef } from "react";
import EmailJS from "emailjs-com";
import env from "react-dotenv";

const ContactMe = () => {
  const emailInputRef = useRef();
  const mesInputRef = useRef();
  const nameInputRef = useRef();

  const handleOnSubmit = (e) => {
    e.preventDefault();
    EmailJS.sendForm(
      env.SERVICE_ID,
      env.TEMPLATE_ID,
      e.target,
      env.USER_ID
    ).then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <div>
      <div className="headerCo">
        <h1>Contact Me</h1>
      </div>
      <div className="formCard">
        <form className="form-c" onSubmit={handleOnSubmit}>
          <div className="form-con">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              required
              name="email"
              id="email"
              ref={emailInputRef}
              placeholder="Email"
            />
          </div>
          <div className="form-con">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              required
              name="from_name"
              id="name"
              ref={nameInputRef}
              placeholder="Name"
            />
          </div>
          <div className="form-con">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              required
              rows="5"
              name="message"
              ref={mesInputRef}
              placeholder="Message"
            ></textarea>
          </div>
          <div className="actions">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactMe;
