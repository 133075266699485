import "./App.css";

import { Route, Routes } from "react-router-dom";
import ContactMe from "./routes/ContactMe";
import ArtPortfolio from "./routes/ArtPortfolio";
import Main from "./routes/Main";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path="/contact-me" element={<ContactMe />} />
        <Route exact path="/art-portfolio" element={<ArtPortfolio />} />
      </Routes>
    </>
  );
}

export default App;
