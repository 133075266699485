import "./ArtPortfolio.css";
import two from "../image/artPortfolio/2.png";
import three from "../image/artPortfolio/3.png";
import four from "../image/artPortfolio/4.png";
import five from "../image/artPortfolio/5.png";
import six from "../image/artPortfolio/6.png";
import seven from "../image/artPortfolio/7.png";
import eight from "../image/artPortfolio/8.png";
import nine from "../image/artPortfolio/9.png";
import ten from "../image/artPortfolio/10.png";
import p11 from "../image/artPortfolio/11.png";
import p12 from "../image/artPortfolio/12.png";
import p13 from "../image/artPortfolio/13.png";
import p14 from "../image/artPortfolio/14.png";
import p15 from "../image/artPortfolio/15.png";
import p16 from "../image/artPortfolio/16.png";
import p17 from "../image/artPortfolio/17.png";
import p18 from "../image/artPortfolio/18.png";
import p19 from "../image/artPortfolio/19.png";
import p20 from "../image/artPortfolio/20.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton } from "@mui/material";
import { useState } from "react";

const ArtPortfolio = () => {
  const temp = [
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    p11,
    p12,
    p13,
    p14,
    p15,
    p16,
    p17,
    p18,
    p19,
    p20,
  ];
  const [imgIndex, setImgIndex] = useState(0);
  var imgChange = imgIndex;

  return (
    <>
      <div className="headerA">
        <h1>Art Portfolio</h1>
      </div>
      <div className="cardA">
        <IconButton>
          <ArrowBackIosNewIcon
            onClick={() => {
              console.log("clickedBack");
              imgChange = imgChange ? imgChange-- : temp.length - 1;
              setImgIndex(imgChange);
            }}
            className="iconsB"
          />
          <ArrowForwardIosIcon
            onClick={() => {
              console.log("clickedFront");
              imgChange = (imgChange + 1) % temp.length;
              setImgIndex(imgChange);
            }}
            className="iconsF"
          />
        </IconButton>

        <img src={temp[imgIndex]} alt="chineseGirl" />
      </div>
    </>
  );
};

export default ArtPortfolio;
