import "../App.css";

import imgFirstRow from "../image/main/firstRow.png";
import imgSecondRow from "../image/main/secondRow.png";
import imgThirdRow from "../image/main/thirdRow.png";
import imgSelf from "../image/main/self.png";
import imgSelfBg from "../image/main/bg.png";
import imgLine from "../image/main/line.png";
import imgLine2 from "../image/main/line2.png";
import imgTimeLine from "../image/main/timeline.png";
import imgTimeLineExt from "../image/main/timelineExt.png";

import { Link } from "react-router-dom";

const Main = () => {
  return (
    <div>
      <h1 className="welcome">Welcome.</h1>
      <h1 className="subWelcome">
        I am Kay Ti, a rising senior at Purdue University. And this is my
        portfolio.
      </h1>
      <img className="selfPicBg" src={imgSelfBg}></img>
      <img className="selfPic" src={imgSelf}></img>
      <img className="line1" src={imgLine}></img>
      <img className="line2" src={imgLine}></img>
      <img className="line3" src={imgLine}></img>
      <img className="line4" src={imgLine}></img>
      <img className="line5" src={imgLine}></img>
      <img className="line6" src={imgLine}></img>
      <div className="selfLabel1">Design Lead @ EPICS - DISC, Purdue</div>
      <div className="selfLabel2">Research Assistant @ Purdue University</div>
      <div className="selfLabel3">
        President of Flutter Development Club @ Purdue University
      </div>
      <div className="selfLabel4">SWE Intern @ SGNL.Ai</div>
      <div className="selfLabel5">Teaching Assistant @ Purdue University</div>
      <div className="selfLabel6">Android Mobile Developer</div>

      <h2>SKILLS</h2>
      <img className="line" src={imgLine2}></img>
      <div className="skillType1">
        Package/
        <br />
        Framework
      </div>
      <div className="skillType2">Database</div>
      <div className="skillType3">Language</div>
      <img className="lineSkill1" src={imgLine2}></img>
      <img className="lineSkill2" src={imgLine2}></img>
      <img className="lineSkill3" src={imgLine2}></img>

      <img className="firstRow" src={imgFirstRow}></img>
      <img className="secondRow" src={imgSecondRow}></img>
      <img className="thirdRow" src={imgThirdRow}></img>

      <div className="desc"></div>
      <div className="interest">Interest</div>
      <p>
        I am mainly interested in Security, and Database and Information
        Systems. I've been working with both relational and non-relational
        database systems in my past projects and I'm excited to see how they
        will change in the future. My internship experience got me interested in
        authentication and keeping data secure. I also like to have fun
        developing mobile application. I'm always open for future working
        opportunities!
      </p>

      <h3 className="experience">EXPERIENCE</h3>
      <img className="lineExp" src={imgLine2}></img>
      <img className="timeLine" src={imgTimeLine}></img>
      <img className="extension" src={imgTimeLineExt}></img>

      <img className="expLine1" src={imgLine2}></img>
      <div className="expLabel1">SWE Intern @ SGNL.Ai</div>
      <div className="date1">May - August 2022</div>
      <div className="date1">May - August 2023</div>
      <div className="expDesc1">
        I helped the team to developed a CLI tool to convert CSV files with
        large datasets into tangible and managable data in databases. This is my
        first internship experience, learning new languages, frameworks and
        databases, as well as seeing the whole software development cycle coming
        into life. The experience has inspired me to learn more about
        authentication and security between softwares.
      </div>

      <img className="expLine2" src={imgLine2}></img>
      <div className="expLabel2">Flutter Development Club President</div>
      <div className="date2">May 2022 - May 2023</div>
      <div className="expDesc2">
        I has organized events and activities for the club for the incoming
        academic year 2022-23. Since this is a relatively new club at Purdue, I
        was excited to see the possibilities for this club to flourish and help
        inspiring and guiding students to use Flutter for developing software
        applications. As the president and someone who used Flutter a lot, it is
        my duty to share my enthusiasm with other fellow students about this
        awesome framework.
      </div>

      <img className="expLine3" src={imgLine2}></img>
      <div className="expLabel3">Research Assistant @ Purdue</div>
      <div className="date3">January 2022 - December 2022</div>
      <div className="expDesc3">
        I was part of the Single Mother Families Project team at Purdue under
        the Human Development and Family Studies Department, and I helped
        transcribing interviews from participants and translating them into
        tangible data. It was truly a humbling experience reading stories from
        these participants who showed great preseverance, and it just inspired
        me more to hopefully contribute back to society and to those who need
        help.
      </div>

      <img className="expLine4" src={imgLine2}></img>
      <div className="expLabel4">Mobile App Developer</div>
      <div className="date4">April 2022 - June 2022</div>
      <div className="expDesc4">
        I helped a professor and his team under the Engineering department to
        developed a survey mobile application for patients to collect their
        daily health data. It was part of a research the team was conducting to
        bring better medical services to the patients. It was an extremely
        rewarding process and learning feedbacks from the team and the
        professor. The app was developed using Flutter and Firebase.
      </div>

      <img className="expLine5" src={imgLine2}></img>
      <div className="expLabel5">Design Lead @ EPICS-DISC</div>
      <div className="date5">August 2021 - May 2022</div>
      <div className="expDesc5">
        I led a team of 6 &#40; Aug - Dec &#41; and 10 &#40; Jan - May &#41; to
        develop a mobile application for high school students to aid them in
        learning Biology. Over the two semesters, we have successfully created 5
        - 6 features, including one-stop hub for all scores across different
        websites. I had a great experience working with my team members. The app
        was developed using Flutter and MongoDB.
      </div>

      <img className="expLine6" src={imgLine2}></img>
      <div className="expLabel6">Teaching Assistant @ Purdue</div>
      <div className="date6">August 2021 - Present</div>
      <div className="expDesc6">
        I was teaching two courses - Computer Architecture, and Data Structure
        And Algorithm. I had office hours to guide students in their coursework
        and explain to them the concepts taught in class, as well as helped
        students during their lab as they built circuits. It was my greatest
        pleasure to be able to serve the students in the way I was served by my
        former teaching assistants. I enjoyed my courses a lot, and I most
        certaintly hope that my students enjoyed them as well.
      </div>

      <Link to="/contact-me">
        <div className="button1">Click Here</div>
      </Link>
      <Link to="/art-portfolio">
        <div className="button2">Art Portfolio</div>
      </Link>
      <div className="descButton1">Want to connect?</div>
      <div className="descButton2">Want to see more?</div>
      <div className="spaces">spaces spaces</div>
    </div>
  );
};

export default Main;
